import { Injectable } from "@angular/core";
import { HttpClient   } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { AuthService } from '../../admin/_services/auth.service';
import { Router } from "@angular/router";
import { AlertService } from ".";

@Injectable()
export class AuthenticationService {

    user: any = [];
    constructor(private _router: Router,private authService: AuthService, private _alertService: AlertService,private http: HttpClient  ) {
    }

    async login(email: string, password: string) {
        var obj = { "email": email, "pass": password };
        await this.authService.login(obj).then(data => {
            console.log("data: ", data);
            this.user = data;
        }).catch(function (e){
            console.log(e);
            return false;
        });
        if(this.user.payload){
            if(this.user.payload.length == 0){
                return false;
            }
            return await localStorage.setItem('pipoohAfiliado', this.user.token);
        }
        else{
            return false;
        }           
             
    }
    async codigoCheck(codigo: string)
    {
        return await this.authService.codigoCheck({"codigo":codigo}).toPromise().then(data => 
            {
                var aux : any = data;
                console.log("cCheck: ",aux);
                return aux;
            });
    }
    async signUp(name: string, email: string, password: string, passwordConfirm: string,  cpf: string,instagram: string,telefone: string) {
        if (password != passwordConfirm) {console.log("xablau"); return false;}
        if(instagram ==null ||instagram==undefined){instagram=""}
        var obj = { "nome": name, "email": email, "senha": password, "cpf": cpf,"instagram": instagram,"telefone": telefone };
        await this.authService.signUp(obj).toPromise().then(data => {
            //console.log(data);
            this.user = data;
            this.user = this.user.payload[0];

        }).catch(function (e){
            console.log(e);
            return false;
        });
        console.log(this.user)
            if(this.user.length == 0){
                return false;
            }
            console.log(this.user.token);
            if(String(this.user.token).substring(0,15) == "SQLSTATE[23000]"){ return "E-mail cadastrado";}
            else{
                return await localStorage.setItem('pipoohAfiliado', this.user.token); 
            }
               
    }

    async forgotPassword(email: string) {
        var obj = { "email": email };
        await this.authService.forgotPassword(obj).toPromise().then(data => {
            this.user = data;
        }).catch(function (e){
            console.log(e);
            return false;
        });
        if(this.user.payload){
            if(this.user.payload.length == 0){
                return false;
            }
            return await localStorage.setItem('pipoohAfiliado', this.user.token);
        }
        else{
            return false;
        }           
             
    }
    async rdConversao(name: string, email: string,  instagram: string, telefone: string) {
        var obj = { "name": name, "email": email,  "instagram": instagram, "telefone": telefone};
        this.authService.rdConversao(obj).then(data => {
            this.user = data;
        }).catch(function (e) {
            console.log(e);
            return false;
        });
    }
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('pipoohAfiliado');
    }
}