import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AuthService } from '../../../admin/_services/auth.service';
import { environment } from '../../../../environments/environment.prod';
import { ActivatedRoute, Router } from '@angular/router';

declare let mLayout: any;
@Component({
    selector: "app-header-nav",
    templateUrl: "./header-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, AfterViewInit {

    public URL = `${environment.domain}`;
    infoEstabelecimento: any = [];
    imagem: any;
    constructor(private _authService: AuthService, 
        private _router: Router) {

    }
    ngOnInit() {
        this.getInfoEstabelecimento();
    }
    getInfoEstabelecimento(){
        let data = localStorage.getItem('pipoohAfiliado'); 
        this._authService.getTokenAfiliado({"token":data}).then(data =>
            {
                this.infoEstabelecimento = data;
                this.infoEstabelecimento = this.infoEstabelecimento.payload[0];
                if(this.infoEstabelecimento == false || this.infoEstabelecimento == null) { this._authService.logout();  this._router.navigate([`/logout`]); }
                if(this.infoEstabelecimento.logo){
                    this.imagem = "https://img.pipooh.com.br/" + this.infoEstabelecimento.logo;
                }
            }).catch(e => { console.log(e); });
    }
    ngAfterViewInit() {

        mLayout.initHeader();

    }

}