import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private URL = `${environment.api}`;

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  postData(url, formData): Promise<any> {
    return this.http.post(url, formData).toPromise().then(response => {
      var aux: any = response;
      if (aux.success == false) {
        this.toastr.error(aux.error, "Erro de solicitação");
        return aux;
      }
      else {
        return aux;
      }
    });
  }

  login(formData) {
    return this.postData(`${this.URL}auth/afiliado/login`, formData);
  }
  signUp(formData) {
    return this.http.post(`${this.URL}auth/afiliado/signup`, formData);
  }
  signUpJuridica(formData) {
    return this.postData(`${this.URL}auth/afiliado/signup/juridica`, formData);
  }
  getTokenAfiliado(formData) {
    return this.postData(`${this.URL}auth/afiliado/token`, formData);
  }
  forgotPassword(formData) {
    return this.http.post(`${this.URL}auth/forgotPassword`, formData);
  }
  codigoCheck(formData) {
    return this.http.post(`${this.URL}auth/codigoCheck`, formData);
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('pipoohAfiliado');
  }
  rdConversao(formData){
    return this.postData(`${this.URL}rdstation/conversao/cadastro/afiliado`, formData);

  }
  //   logout() {
  //     return this.http.get(`${this.URL}logout`);
  //   }
  //   loged() {
  //     return this.http.get(`${this.URL}loged`);
  //   }
}
